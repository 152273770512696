// Overall page styling
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
  // cursor: none;
  // user-select: none;
  // -webkit-user-drag: none;
}

.icon-link {
  cursor: pointer;

  // width: 0.5px;
  size: 1em;
  // padding: 1em;
  padding-bottom: 2em;


}



.social-menu {
  // cursor: pointer;
    // margin: 0;
  float: right;
  margin-top: 10%;
  // margin-left: 80%;
  a:hover {
    transform: scale(1);
  }


}

.logo-social {

  width: 1.6em;
  padding-bottom: 0.6em;


}

//body styling
body {
  overflow: hidden;
  background: #fff;
  color: white; // font color
  height: 100vh;
  margin: 0;
  font-family: "Poppins";
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: black;

  :visited {
    text-decoration: none;
  }
}

//social media icon container
.container {
  margin-top: 5em;
  padding-right: 15%;
  display: block;
  float: right;
}

//naviagation bar styling
.cta {
  text-decoration: none;
  color: aliceblue;
  font-size: 1.2rem;
  // cursor: pointer;
}


@media only screen and (min-width: 600px) {
  header {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15%;
  }


}





.hamburger-container {
  display: table-cell;
  padding-right: 20px;


}


.logo {
  // width: 5%;
  width: 2em;
}

.app_icon {
  // size: 5em;
  width: 1%;
}



// navigation bar text
.header-text {
  --text: rgb(255, 255, 255);
  --font-size: 16px;
  font-weight: 300;
  line-height: 1;
  border-radius: 24px;
  display: table-cell;
  padding: 1em;
  // padding-right: 2.3em;
  outline: none;
  text-decoration: none;
  font-size: var(--font-size);
  letter-spacing: .5px;
  background: var(--background);
  // color: var(--text);
  color: #000;
  box-shadow: var(--shadow);

  div {
    display: flex;

    span {
      display: block;
      backface-visibility: hidden;
      font-style: normal;
    }
  }


}



.bodysection {
  // color: rgb(255, 255, 255);
  position: relative;
  // padding: 0em 0em 2em 2em;
  padding: 1em 15%;
  height: 100vh;
}

//cursor styling
.cursor {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: #F2DD8C;
  position: absolute;
  z-index: 999;
  pointer-events: none;
  box-shadow: 0px 0px 15px -5px white;
  mix-blend-mode: difference;

  &-f {
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.4202 42.4202C38.8403 46 33.3594 46 23.5 46C13.6406 46 8.15966 46 4.57983 42.4202C1 38.8403 1 33.3594 1 23.5C1 13.6406 1 8.15966 4.57983 4.57983C8.15966 1 13.6406 1 23.5 1C33.3594 1 38.8403 1 42.4202 4.57983C46 8.15966 46 13.6406 46 23.5C46 33.3594 46 38.8403 42.4202 42.4202Z' stroke='white'/%3E%3C/svg%3E%0A");
    background-size: cover;
    mix-blend-mode: difference;
    pointer-events: none;
    opacity: 0;
  }
}


.loader_container {
  min-height: 100vh;
  background: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  --path: #fff;
  --dot: #F2DD8C;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--dot);
    top: 37px;
    left: 19px;
    transform: translate(-18px, -18px);
    animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;

    circle {
      fill: none;
      stroke: var(--path);
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    circle {
      stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
      stroke-dashoffset: 75;
      animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }

}

.text1 {
  font-weight: 500;
  font-size: 50px;

  color: #000000;



  &span {
    font-weight: 500;
    color: #000;
    // font-size: 20%;
  }

  padding-bottom: 0.7%;
}

.text2 {
  font-weight: 500;
  font-size: 50px;

  color: #000000;
}

.text3 {
  font-weight: 300;
  margin-top: 2em;
  color: #000;
  font-size: medium
}

.highlight {
  color: #000;
  background-color: #FFFAA3;

}

.highlight:hover {
  -webkit-text-stroke: 1.8px black;
  background-color: #fff;
  color: #fff;
  cursor: pointer;
}

// design
.outline-highlight1 {
  color: #fff;
  // cursor: pointer;
  -webkit-text-stroke: 1.3px black;
}

.outline-highlight1:hover {
  cursor: pointer;
  // -webkit-text-stroke: 1.8px black;
  // background-color: #fff;
  // color: #fff;
  color: #000;
  background-color: #A38ED2;
}

// edit
.outline-highlight2 {
  color: #fff;
  cursor: pointer;
  -webkit-text-stroke: 1.3px black;

}

.outline-highlight2:hover {
  color: #000;
  background-color: #ff8281;
}

// 

// write
.outline-highlight3 {
  color: #fff;
  cursor: pointer;
  -webkit-text-stroke: 1.3px black;
}

.outline-highlight3:hover {
  // -webkit-text-stroke: 1.8px black;
  // background-color: #fff;
  // color: #fff;
  color: #000;
  background-color: #A2C5FE
}

.outline-highlight4 {
  color: #fff;
  cursor: pointer;
  -webkit-text-stroke: 1.3px black;
  margin: 0;
  padding: 0;

}

.outline-highlight4:hover {

  color: #000;
  background-color: #ffd28a
}





.look-btn {
  color: #000;
  // display: block;
  opacity: 60%;
  font-size: 0.7em;

  // transition: ease-in-out, 1s;

  :hover {
    background-color: white;
    color: #1a1a1a;
    transition: ease-in-out, 1s;
  }
}

.video_box {
  display: flex;
  justify-content: space-evenly;
  padding: 1em;
}



.achievement-txt {
  font-size: 0.9em;
  // font-weight: bold;
  opacity: 50%;

  margin-top: 3em;
  margin-bottom: 0.8em;
  color: #000;
}

.a-txt-area {
  color: #000;
  // opacity: 50;
}

.a-txt-area1 {
  color: #000;
  opacity: 10;
}

// .f-btn {
//   font-weight: 600;
//   letter-spacing: 2px;
//   padding: 0.5em 1em 0.5em 1em;
//   // display: flex;
//   float: left;
//   border: 2px solid white;

// }


.nav-items{
  float: right;
  cursor: pointer;
  font-size: medium;
  a{
    color:rgb(97, 97, 97);
  }
  li{
    display: inline;
    padding-right: 1em;
    
  }
  li:hover{
    color:black;
  }
  
}

.text-area {
  padding-top: 5%;
  padding-bottom: 5%;
  // display: flex;
  z-index: 10000;
}


@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}


img {

  object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.gallery-wrapper {
  padding: 3rem 0rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-auto-flow: dense;
}


.gallery-item {

  width: 100%;
  height: 100%;
  position: relative;

}

.gallery-item .image {
  border-radius: 1rem;
  // width: 25%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  // cursor: pointer;
  transition: all 400ms ease-in;
  transform: scale(1);
}

.gallery-item .image img:hover {
  transform: scale(1.1);
}

.w-1 {
  grid-column: span 1;
}

.w-2 {
  grid-column: span 2;
}

.w-3 {
  grid-column: span 3;
}

.w-4 {
  grid-column: span 4;
}

.w-5 {
  grid-column: span 5;
}

.h-1 {
  grid-row: span 1;
}

.h-2 {
  grid-row: span 2;
}

.h-3 {
  grid-row: span 3;
}

.h-4 {
  grid-row: span 4;
}

.h-5 {
  grid-row: span 5;
}